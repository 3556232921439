import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Heading, ResponsiveImage, Text } from '@attentive/picnic';

import zeroStateImage from '../../../assets/report-zero-state.svg';

const PU_MAX_WIDTH = 400;

export const ReportingFeatureUnavailable = ({
  pageHeader,
  errorMessage,
  backLink,
}: {
  pageHeader?: {
    title: string;
    subTitle: string | React.ReactNode;
  };
  errorMessage: {
    title: string;
    subTitle: string | React.ReactNode;
  };
  backLink: { text: string; location: string };
}) => (
  <Box css={{ height: '100%' }}>
    {pageHeader && (
      <Box>
        <Heading variant="page" css={{ mb: '$space2' }}>
          {pageHeader.title}
        </Heading>
        <Text>{pageHeader.subTitle}</Text>
      </Box>
    )}
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        my: '$space4',
        textAlign: 'center',
        height: '70%',
      }}
    >
      <ResponsiveImage
        ratio={1288 / 840}
        alt="Page Not Found"
        src={zeroStateImage}
        css={{ width: '100%', mb: '$space8', maxWidth: PU_MAX_WIDTH }}
      />
      <Heading variant="md" css={{ mb: '$space4' }}>
        {errorMessage.title}
      </Heading>
      <Text css={{ mb: '$space4' }}>{errorMessage.subTitle}</Text>
      <Link to={backLink.location} style={{ fontWeight: 500, textDecoration: 'underline' }}>
        {backLink.text}
      </Link>
    </Box>
  </Box>
);
